<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Nodot pasūtījumu piegādei
            </h3>
        </template>

        <template v-slot:content>

            <template v-if="form.delivery_type">
                <div class="flex h-full items-center pt-4">
                    <Radio radioName="deliveryType" v-model="form.delivery_type" :items="orderTypes" :errors="errors.delivery_type" />
                </div>
            </template>

            <div class="pt-3 w-full">
                <div class="flex justify-end">
                    <button @click="hideForm" type="button"
                            class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                        Atcelt
                    </button>

                    <template v-if="!loading">
                        <button type="button"
                                class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                                @click.prevent="submit">
                            Nodot
                        </button>
                    </template>
                    <template v-else>
                        <Loading/>
                    </template>
                </div>
            </div>
        </template>
    </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import Loading from "@/components/Components/Loading";
import Radio from "../../Components/Radio";
import Validation from "@/modules/Validation";

export default {
    name: "OrderDeliveryForm",
    components: {
        ItemCard,
        Loading,
        Radio,
    },
    props: ['order', 'status_id'],
    data: () => ({
        form: {
            delivery_type: null,
        },
    }),
    computed: {
        ...mapGetters({
            loading: "loading",
            mainCustomer: 'systemMainCustomer',
            errors: "errors",
            orderTypes: "orderTypes",
        }),
        formValidation() {
            return {
                delivery_type: {
                  rules: ['required']
                },
            }
        }
    },
    created() {
        this.$store.dispatch('getDeliveryTypes').then(() => {
            this.form.delivery_type = this.orderTypes[0].value
        });
    },
    methods: {
        hideForm(){
            this.$store.dispatch('removeAllFormsForDisplay')
        },
        submit() {
            this.$Progress.start()
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateOrder", {
                    id: this.order.id,
                    data: {
                        status_id: this.status_id,
                        delivery_type: this.form.delivery_type,
                    },
                })
            }  else this.$Progress.fail()
        }
    }
}
</script>

<style scoped>

</style>